export default function Navbar () {

    const logout = () => {
        localStorage.removeItem("CS_VIEW");
        localStorage.removeItem('USER_SESSION');
        window.location.href = '/login';
    }
    return (
 
<nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{backgroundColor: 'rgb(237, 237, 237)'}}>
<div className="container-fluid">
  
   <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon" /></button>
   <div className="collapse navbar-collapse" id="navbarText">
      <ul className="navbar-nav me-auto" />
      <a onClick={logout} className="navbar-text">Logout</a>
   </div>
</div>
</nav>
    )
}