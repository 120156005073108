export default {
    langIso: "en",
    hello: "Hello",
    fillTheForm: "Fill the form to continue.",
    dealId: "Deal ID",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    email: "Email",
    address: "Address",
    location: "Select a location",

    continue: "Continue",

    watchVideo: "Watch this short video to continue.",
    watchAllVideoToContinue: "Watch the entire video to continue",

    emailDocuments: "Email Documents",
    emailDocumentsDescription: "You will receive by email the documents detailing your rights in terms of insurance.",

    signature: "Signature",

    signature1: "I have watched the video and read the regulatory document, and I agree to the terms and conditions.",
    signature2: "I authorize HGrégoire to record my conversation with the sales agent and to keep the recording for a period of 5 years in accordance with applicable laws.",
    handSign: "Sign here",

    record: "Deal information",
    recordButtonStart: "Continue the session",
    recordButtonStop: "End the session",

    confirmEnd: "Are you sure you want to end the session?",

    thanks: "HGrégoire vous remercie.",

    login: {
        title: "Login to",
        incorrectCredentials: "Incorrect credentials, please try again",
    },

    recording: "Recording in progress",
}