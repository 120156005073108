import React, {useEffect, useRef} from "react";
import axios from "axios";
import {connect, useStore} from 'react-redux';
import {Helmet} from 'react-helmet';
import FadeIn from "react-fade-in"
import { post } from "../tools";
import envConfig from "../env.config";
import {useLocation} from "react-router-dom";
const Login = () => {

    const [failedToLogin, setFailedToLogin] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");


    const divRef = useRef(null);

     const login = e => {
        e.preventDefault();
        post("/auth/login", {
            email, 
            password, 
        }).then(res => {
            window.localStorage.setItem("USER_SESSION", res.data.accessToken);
            window.location.href = "/";
        }).catch(() => setFailedToLogin("Incorrect credentials"))
    }

    useEffect(() => {
    if (divRef.current) {
    window.google?.accounts.id.initialize({
        client_id: "1074074934430-2k54pa18hsv7sgln4ashmq8bvg1sq120.apps.googleusercontent.com",
            callback: (res, error) => {
              axios.post(envConfig().API_URL+"/auth/google", {
                  credential: res.credential
              }).then(res => {
                  window.localStorage.setItem("USER_SESSION", res.data);
                  window.location.href = "/";


              }).catch(() => setFailedToLogin("Incorrect credentials"))
            },
            });
            window.google?.accounts.id.renderButton(divRef.current, {
                theme: 'outline',
                size: 'large',
                type: 'standard',
                text: 'continue_with',
                shape: "pill",
                logo_alignment: "left",
                width: "400"
            });
            }
            }, [divRef.current]);

    return (
        <React.Fragment>
            <Helmet>
                <link href={"/auth.css"} rel="stylesheet" crossorigin="anonymous"/>
            </Helmet>
            <div className="container-fluid ps-md-0">
                <div className="row g-0">
                    <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image" />
                    <div className="col-md-8 col-lg-6">
                        <div className="login d-flex align-items-center py-5">
                            <div className="container">
                            <div className="row">
                                <div className="col-md-9 col-lg-8 mx-auto">
                                    <img src="https://mms.businesswire.com/media/20200916005892/fr/822032/5/A4RdwkEQ.jpg" width="200dpx" />
                                        <hr/>
                                        <h5 className="card-title">Login to continue</h5>

                                        {failedToLogin
                                            ? <div className="alert alert-danger">Login failed, please try again</div>
                                            : null
                                        }
                                       <form className="form-signin" onSubmit={login}>
                                            <div ref={divRef} />
                                            <hr/>

                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="email" 
                                                    id="email" 
                                                    className="form-control" 
                                                    placeHolder="Email" 
                                                    name="email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    required 
                                                    autoFocus 
                                                />
                                                <label htmlFor="floatingInput">Email address</label>
                                            </div>
                                            <div className="form-floating mb-3">
                                                <input 
                                                    type="password" 
                                                    id="password" 
                                                    className="form-control" 
                                                    placeholder="Password" 
                                                    name="password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    required  
                                                />
                                                <label htmlFor="floatingPassword">Password</label>
                                            </div>
                                            <div className="d-grid">
                                                <button className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">Sign in</button>

                                            </div>
                                            {/* <hr/>
                                            <h6 style={{color: "gray"}}>Matador Check-in v0.1.0</h6> */}
                                        </form>

                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default Login;


       
  