import React, { useState, useEffect } from "react";
import { get, post } from "../tools";
import langContent from "../lang";


export default function PrefillModal () {

    const [dealId, setDealId] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");

    const [reloadMode, setReloadMode] = useState(1);

    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState("");

    const lang = langContent["en"];

    const [alert, setAlert] = useState(0);

    useEffect(() => {
        get("/location")
        .then(res => setLocations(res.data))
        .catch(err => console.log(err));
    }, []);

    const createSession = customer => post("/session/create", {
        lang: lang.langIso,
        location: customer.location,
        customer: {
            ...customer,
            location: undefined
        },
        setActive: reloadMode === 2 ? true : false
    })
    .then(res => {
        setAlert(1);
        setTimeout(() => window.location.reload(), 1000)
    })
    .catch(() => setAlert(2));


    return (
        <div className="modal fade" id={"exampleModalPrefill"} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-header">
                        Prefill a deal
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        {alert === 1 ? <div className="alert alert-success">Saved successfully !</div> : null}
                        {alert === 2 ? <div className="alert alert-danger">Error while saving</div> : null}
                        <form onSubmit={e => {
                            e.preventDefault();
                            createSession({firstName, lastName, email, phoneNumber, address: "empty", location, dealId});
                        }}>
                            <div className="form-group">
                                <label htmlFor="firstName"/>
                                <input type="text" className="form-control" id="dealId" placeholder={lang.dealId} value={dealId} onChange={e => setDealId(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="firstName"/>
                                <input type="text" className="form-control" id="firstName" placeholder={lang.firstName} value={firstName} onChange={e => setFirstName(e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName"/>
                                <input type="text" className="form-control" id="lastName" placeholder={lang.lastName} value={lastName} onChange={e => setLastName(e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="phoneNumber"/>
                                <input type="text" className="form-control" id="phoneNumber" placeholder={lang.phoneNumber} value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email"/>
                                <input type="email" className="form-control" id="email" placeholder={lang.email} value={email} onChange={e => setEmail(e.target.value)} required/>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="address"/>
                                <input type="text" className="form-control" id="address" placeholder={lang.address} value={address} onChange={e => setAddress(e.target.value)} required/>
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="address"/>
                                <select className="form-control" required value={location} onChange={e => setLocation(e.target.value)}> 
                                    <option value="">{lang.location}</option>


                                    {locations.map(location => <option key={location._id} value={location._id}>{location.name}</option>)}
                                </select>
                            </div>
                            <br/>
                            <div className="d-grid gap-2 col-12 mx-auto">
                                <button onClick={() => setReloadMode(1)} type="submit" className="btn btn-block btn-sm btn-primary">Save deal</button>
                                <button onClick={() => setReloadMode(2)} type="submit" className="btn btn-block btn-sm btn-outline-primary">Save deal and open customer view</button>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}