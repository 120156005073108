import React, {Component} from 'react';
import {render} from 'react-dom';
import ReactMicRecord from 'react-mic-record';

require('./styles.scss');

export default class TestAudio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: false,
            blobObject: null,
            isRecording: false,
            supportsCaffeine: false
        }
    }
    
    startRecording() {
        this.setState({
            record: true,
            isRecording: true
        });
    }
    
    stopRecording() {
        this.setState({
            record: false,
            isRecording: false
        });
    }
    
    onStart() {
        console.log('You can tap into the onStart callback');
    }
    
    onStop(blobObject) {
        this.setState({
            blobURL: blobObject.blobURL
        });
    }

    updatePermission = status => {
        this.setState({permission: status});
    }

    async componentDidMount () {


        const permission = await navigator.permissions.query(
            { name: 'microphone' }

        )

        permission.onchange = function(a) {
            window.location.reload();
        }


        this.updatePermission(permission.state);

        if ('wakeLock' in navigator) {
            this.setState({supportsCaffeine: true});
            navigator.wakeLock.request();
        }
    }
    
    render() {
        const {isRecording, blobURL} = this.state;
        
        return (
            <React.Fragment>
                <div className="modal fade" id={"exampleModalTestAudio"} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content">
                            
                            <div className="modal-header">
                                Test microphone
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-12'>
                                    {this.state.permission === "denied" ? <div className="alert alert-danger">
                                        Access to microphone denied. Please update the mic permission and reload the page to continue
                                    </div> : null} 
                                    <ReactMicRecord
                                        className="oscilloscope"
                                        record={this.state.record}
                                        backgroundColor="green"
                                        audioBitsPerSecond={128000}
                                        onStop={blobObject => this.onStop(blobObject)}
                                        onStart={() => this.onStart()}
                                        strokeColor="#ffffff"
                                    />
                                    </div>
                                   <div className='col-12'>
                                   {!isRecording ?
                                        <React.Fragment>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.startRecording()}>
                                                Record
                                            </button>
                                            <hr/>
                                            <audio ref="audioSource" controls="controls" src={blobURL}/>
                                        </React.Fragment>
                                        :
                                        <div>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.stopRecording()}>
                                                Stop
                                            </button>
                                            {/* <button
                                                className="btn"
                                                disabled={!isRecording}
                                                onClick={() => this.stopRecording()}>
                                                Cancel
                                            </button> */}
                                        </div>
                                    }
                                   </div>

                                   <hr/>

                                   {this.state.supportsCaffeine ? <h6>This device supports screen wake API.</h6>: null}
                                    
                                </div>
                               
                                </div>
                               
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        );
    }
}
