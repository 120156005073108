export default () => {
    return {
        APP_URL: window.location.origin,
        API_URL:
            // window.location.hostname === "localhost"
            //     ? "http://localhost:4056/api"
            //     :
                 "https://api.finance.check-in.matador.ai/api"
    };
};
