import React, { useState, useEffect } from "react";
import { get, post } from "../tools";
import langContent from "../lang";


export default function ReloadModal () {

    const [dealId, setDealId] = useState(null);

    const lang = langContent["en"];

    const [alert, setAlert] = useState(0);

    const find = customer => get("/session/"+dealId)
    .then(res => {
        window.location.href = "/deal/"+res.data._id;
    })
    .catch(() => setAlert(2));


    return (
        <div className="modal fade" id={"exampleModalReload"} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-header">
                        Reload a deal
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        {alert === 2 ? <div className="alert alert-danger">Deal not found. Make sure you have entered the correct deal number and that you have access permissions.</div> : null}
                        <form onSubmit={e => {
                            e.preventDefault();
                            find();
                        }}>
                            <div className="form-group">
                                <label htmlFor="firstName"/>
                                <input type="text" className="form-control" id="dealId" placeholder={lang.dealId} value={dealId} onChange={e => setDealId(e.target.value)}/>
                            </div>
                            <br/>
                            <button type="submit" className="btn btn-primary">{lang.continue}</button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}