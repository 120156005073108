import * as React from "react";
import Navbar from "../library/Navbar";
import { get, post } from "../tools";

export default function CustomerView (props) {

    React.useEffect(() => {
        setInterval(() => post("/session/pingActiveSession").then(res => {
            if(res.data) window.location.href = "/deal/"+res.data._id
        }), 5000);

        if ('wakeLock' in navigator) {
            navigator.wakeLock.request();
        }
    });
    
    return (
        <div className="container">
            <Navbar/>
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <br/>
                    <br/>   
                    <br/>
                    <div className="card">
                        <div className="card-body text-center">
                            <br/><br/>
                            <h3>En attente d'une nouvelle session...</h3>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}