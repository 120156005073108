import axios from "axios";
import envConfig from "./env.config";

const httpClient = method => (url, data, notLogged) => {

    const token = window.localStorage["USER_SESSION"];

    const params = {
        url: envConfig().API_URL+String(url),
        method,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "authorization": "Bearer "+token
        },
        data
    }
    
    return axios(params);

};

export const get = httpClient("get");
export const post = httpClient("post");