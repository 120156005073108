import React, { Fragment, useEffect, useState } from "react";
import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
import Navbar from "../library/Navbar";
import { get, post } from "../tools";
import Voice from "./Voice";

export default function ViewDeal (props) {
    const params = useParams();
    const [deal, setDeal] = useState(null);

    useEffect(() => {
        get("/session/"+params?.dealId)
            .then(res => {
                setDeal(res.data)
            })
            .catch(() => window.location.href = "./")
    }, []);

    return deal ? (
        <div>
            <Navbar/>
            <br/>
            <br/>
            <br/>
            <div className="container">

                <Link to="/" className="btn btn-sm btn-outline-success">
                    <i className="fas fa-chevron-circle-left"></i> Back to dashboard
                </Link>
                <hr/>
                {deal.active ? <React.Fragment>
                    <div className="alert alert-success">
                        <h6>This session is active</h6>
                        <button onClick={() => {
                            post("/session/isActive/"+deal._id, {
                                setActive: false
                            }).then(() => window.location.reload());
                        }} className="btn btn-success"><i className="fas fa-check"/> Finish session</button>
                    </div>
                    <hr/>
                </React.Fragment> : null}
                
                <div className="card">
                    <div className="card-body">
        
                        <h4>#{deal.customer.dealId} - {deal.customer.firstName} {deal.customer.lastName} {deal.step < 6 ? <h6><span className="badge rounded-pill bg-warning text-dark">Not completed</span></h6> : null} </h4>
                        <b>F&I Director: </b> {deal?.user?.firstName} {deal?.user.lastName} <br/>
                        <b>Location: </b> {deal?.location?.name}<br/>
                        <b>Email address:</b> {deal.customer?.email}<br/>
                        <b>Phone Number:</b> {deal.customer?.phoneNumber}<br/>
                        <b>Created at:</b> {moment(deal.createdAt).format("LLLL")}<br/>
                        <b>Documents emailed at: </b> {deal.signedAt ? moment(deal.emailedAt).format("LLLL") : "not emailed yet"}<br/>
                        <b>Completed at:</b> {deal.completedAt ? moment(deal.completedAt).format("LLLL") : "not completed yet"}
                        <hr/>
                        <h5>Signature details</h5>
                        <b>Signed at:</b> {deal.signedAt ? moment(deal.signedAt).format("LLLL") : "not signed yet"}<br/>
                        {deal.signedAt ? <React.Fragment>
                            <b>Location:</b> {deal?.coordinates?.[0]}, {deal?.coordinates?.[1]} <a target="_blank" href={`https://www.google.com/maps/search/${deal?.coordinates?.[0]},${deal?.coordinates?.[1]}/@${deal?.coordinates?.[0]},${deal?.coordinates?.[1]}`}>View on Google Maps</a> <br/>
                            <i className="fas fa-pen-fancy"></i> Signature image:<br/>
                            <img src={`data:image/png;base64,${deal.signatureBase64}`} alt="not signed" width="50%" /> 
                        </React.Fragment> : null}
                        
                        {deal?.audioUrl ? <React.Fragment>
                                <hr/>
                                <Voice audioUrl={deal.audioUrl} transcriptUrl={deal.transcriptUrl}/> 
                            </React.Fragment>
                        : undefined}
                    </div>
                    
                </div>
            </div>
        </div>


    ) : null
}