import React, { useEffect, useState } from "react";
import {ReactMic} from "react-mic";

export default function Test (props) {

   
function handleSuccess(stream) {
    if (window.stream) {
      window.stream.getAudioTracks().forEach(track => track.stop());
      window.stream = null;
    } else {
      const audio = document.createElement('audio');
      audio.controls = true;
      audio.autoplay = true;
      window.stream = stream;
      audio.srcObject = stream;
  
      stream.oninactive = function() {
        console.log('Stream ended');
      };
    }
  }
  
  function handleError(e){
    console.log("ruin", e.message);
  }
  
  document.querySelector("#micTest").addEventListener('click', function(){
    if ( window.stream ) {
      this.innerText = "Test Microphone";
    } else {
      this.innerText = "Stop Test";
    }
    if (navigator.mediaDevices) {
      const constraints = window.constraints = {
        audio: true, 
        video: false
      }
      navigator.mediaDevices.getUserMedia(constraints).then(handleSuccess).catch(handleError)
    }
  });
  
  document.querySelector('#soundTest').addEventListener('click', function(){
      const audio = document.createElement('audio');
      audio.controls = true;
      audio.autoplay = true;
      audio.src = 'https://teste-sdk-rs.s3.amazonaws.com/Purr.wav';
  })
    return (
        <React.Fragment>

            <main className="has-background-dark">
            <div className="is-family-sans-serif  has-text-white has-text-centered">
              <h1 className="is-size-1 has-text-centered is-uppercase has-text-weight-bold">
                Sound check!
              </h1>
              <p className="">See if your microphone and headphones are setup properly</p>
              <p>
                {" "}
                For the Microphone test, a prompt will appear asking for permission to
                access your select output device{" "}
              </p>
              <p>If you are not using headphones, it'll have a loop feedback</p>
              <p />
              <button
                className="button is-primary is-fullwidth is-large"
                id="micTest"
                type="button"
              >
                Test Microphone
              </button>
              <button
                className="button is-link is-light is-fullwidth is-medium"
                id="soundTest"
                type="button"
              >
                Test Headphone/Speakers
              </button>
            </div>
          </main>
          
        </React.Fragment>
        


    )
} 