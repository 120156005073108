import { useEffect } from "react";
import { Outlet } from "react-router-dom";


export default function ContextLoader (props) {

    useEffect(() => {

        const pathname = window.location.pathname;

        if(!window.localStorage["USER_SESSION"] && !pathname.includes("/login")) {
            window.location.href = "/login";
        }
    }, []);

    return <Outlet/>
}