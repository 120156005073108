import React from "react";
import Navbar from "../library/Navbar";
import { post } from "../tools";
import moment from "moment";
import Prefill from "../library/Prefill";
import Reload from "../library/Reload";
import { Link } from "react-router-dom";
import { Player } from "webvtt-player";
import convert from "convert-seconds-to-human";
import Voice from "./Voice";
import TestAudio from "../library/TestAudio";
import jwtDecode from "jwt-decode";

export default function Index() {
    const [query, setQuery] = React.useState("");
    const [deals, setDeals] = React.useState([]);
    const [stats, setStats] = React.useState({});
    const [agent, setAgent] = React.useState(undefined);
    const [role, setRole] = React.useState("");

    const [activeSession, setActiveSession] = React.useState({});

    const search = (query) => {
        setQuery(query);
        post("/session/search", {
            query,
            agent
        }).then((res) => {
            setDeals(res.data);
        });
    };

    const fetchStats = (agentId) => {
        setAgent(agentId);
        post("/stats", {
            agent: agentId
        }).then((res) => {
            setStats(res.data);
            post("/session/search", {
                query: "",
                agent: agentId
            }).then((res) => {
                setDeals(res.data);
            });
        });
    };

    React.useEffect(() => {
        search("");
        fetchStats();
        const updateSession = () =>
            post("/session/pingActiveSession").then((res) => {
                setActiveSession(res.data);
            });
        updateSession();
        setInterval(updateSession, 5000);
        try {
            const token = jwtDecode(window.localStorage.USER_SESSION);
            setRole(token.role);
        } catch (err) {}
    }, []);

    return (
        <div className="container">
            <Navbar />
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <br />
                    <br />
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <img
                                className="text-center"
                                src="https://mms.businesswire.com/media/20200916005892/fr/822032/5/A4RdwkEQ.jpg"
                                width="200px"
                            />
                            <hr />

                            <React.Fragment>
                                <button
                                    style={{ marginRight: "5px" }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target={"#exampleModalPrefill"}
                                    className="btn btn-sm btn-outline-success"
                                >
                                    <i className="fas fa-align-justify"></i>{" "}
                                    Pre-fill a deal
                                </button>

                                <button
                                    style={{ marginRight: "5px" }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target={"#exampleModalReload"}
                                    className="btn btn-sm btn-outline-secondary"
                                >
                                    <i className="fas fa-sync" /> Load
                                    uncompleted deal
                                </button>

                                <button
                                    style={{ marginRight: "5px" }}
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target={"#exampleModalTestAudio"}
                                    className="btn btn-sm btn-outline-secondary"
                                >
                                    <i className="fas fa-microphone" /> Test
                                    microphone
                                </button>

                                <button
                                    onClick={() => {
                                        window.localStorage.setItem(
                                            "CS_VIEW",
                                            true
                                        );
                                        window.location.reload();
                                    }}
                                    style={{ marginRight: "5px" }}
                                    type="button"
                                    className="btn btn-sm btn-outline-secondary"
                                >
                                    <i className="fas fa-check" /> Activate
                                    customer view
                                </button>

                                <Prefill />
                                <Reload />
                                <TestAudio />

                                <hr />

                                {activeSession?._id ? (
                                    <div className="alert alert-success">
                                        <h6>Current session </h6>
                                        {activeSession.customer.firstName}{" "}
                                        {activeSession.customer.lastName} (
                                        {activeSession.customer.dealId}){" "}
                                        <Link
                                            to={
                                                "/deal/view/" +
                                                activeSession._id
                                            }
                                            className="btn btn-sm btn-primary"
                                        >
                                            View
                                        </Link>
                                    </div>
                                ) : null}
                            </React.Fragment>
                            <h4 className="card-title">Search a deal</h4>
                            {role === "admin" ? (
                                <div className="row">
                                    <div className="col-3">
                                        <select
                                            onChange={(e) => {
                                                fetchStats(e.target.value);
                                            }}
                                            className="form-control"
                                        >
                                            <option value={""}>
                                                All F&I Directors
                                            </option>
                                            {stats.agents?.map((agent) => (
                                                <option value={agent._id}>
                                                    {agent.firstName}{" "}
                                                    {agent.lastName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            placeholder="Enter name, deal ID or email address"
                                            className="form-control"
                                            type="text"
                                            value={query}
                                            onChange={(e) =>
                                                search(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <React.Fragment>
                                <hr />
                                <h5 className="card-title">
                                    Total deals completed
                                </h5>
                                <div className="row">
                                    <div className="col-3">
                                        <b>Today:</b> {stats?.todaySessions}
                                        <br />
                                        <b>This week:</b> {stats?.weekSessions}
                                    </div>
                                    <div className="col-3">
                                        <b>Month to date:</b>{" "}
                                        {stats?.MTDSessions}
                                        <br />
                                        <b>All-time:</b>{" "}
                                        {stats?.allTimeSessions}
                                    </div>
                                </div>
                            </React.Fragment>
                            {deals.length ? (
                                <React.Fragment>
                                    <hr />
                                    <h5 className="card-title">Results</h5>
                                    <div className="row">
                                        {deals.map(
                                            (
                                                {
                                                    _id: dealId,
                                                    customer,
                                                    user: director,
                                                    createdAt,
                                                    recordingLength,
                                                    signedAt,
                                                    signatureBase64,
                                                    step,
                                                    audioUrl,
                                                    transcriptUrl
                                                },
                                                i
                                            ) => (
                                                <div className="col-12" key={i}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h5 className="card-title">
                                                                Deal #
                                                                {
                                                                    customer.dealId
                                                                }{" "}
                                                                {step < 6 ? (
                                                                    <h6>
                                                                        <span className="badge rounded-pill bg-warning text-dark">
                                                                            Not
                                                                            completed
                                                                        </span>
                                                                    </h6>
                                                                ) : null}
                                                            </h5>
                                                            <p className="card-text">
                                                                <b>Customer</b>:{" "}
                                                                {
                                                                    customer.firstName
                                                                }{" "}
                                                                {
                                                                    customer.lastName
                                                                }{" "}
                                                                -{" "}
                                                                {customer.email}
                                                                <br />
                                                                <b>
                                                                    F&I director
                                                                </b>
                                                                :{" "}
                                                                {
                                                                    director.firstName
                                                                }{" "}
                                                                {
                                                                    director.lastName
                                                                }
                                                                <br />
                                                                {recordingLength ? (
                                                                    <React.Fragment>
                                                                        <b>
                                                                            Recording
                                                                            duration
                                                                        </b>
                                                                        :{" "}
                                                                        {
                                                                            convert(
                                                                                recordingLength
                                                                            )
                                                                                .minutes
                                                                        }{" "}
                                                                        min{" "}
                                                                        {
                                                                            convert(
                                                                                recordingLength
                                                                            )
                                                                                .seconds
                                                                        }{" "}
                                                                        sec
                                                                        <br />
                                                                    </React.Fragment>
                                                                ) : null}
                                                                {moment(
                                                                    createdAt
                                                                ).format(
                                                                    "LLLL"
                                                                )}
                                                            </p>

                                                            <React.Fragment>
                                                                <Link
                                                                    to={
                                                                        "/deal/view/" +
                                                                        dealId
                                                                    }
                                                                    style={{
                                                                        marginRight:
                                                                            "5px"
                                                                    }}
                                                                    type="button"
                                                                    className="btn btn-sm btn-outline-primary"
                                                                >
                                                                    <i className="fas fa-plus" />{" "}
                                                                    Show details
                                                                </Link>
                                                                {step < 6 ? (
                                                                    <React.Fragment>
                                                                        <button
                                                                            style={{
                                                                                marginRight:
                                                                                    "5px"
                                                                            }}
                                                                            className="btn btn-sm btn-outline-secondary"
                                                                            onClick={() => {
                                                                                post(
                                                                                    "/session/isActive/" +
                                                                                        dealId,
                                                                                    {
                                                                                        setActive: true
                                                                                    }
                                                                                ).then(
                                                                                    () =>
                                                                                        window.location.reload()
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-check"></i>{" "}
                                                                            Load
                                                                            on
                                                                            customer
                                                                            screen
                                                                        </button>
                                                                        <Link
                                                                            className="btn btn-sm btn-outline-secondary"
                                                                            to={`/deal/${dealId}`}
                                                                        >
                                                                            <i className="fas fa-user" />{" "}
                                                                            Customer
                                                                            View
                                                                        </Link>
                                                                    </React.Fragment>
                                                                ) : null}
                                                            </React.Fragment>
                                                        </div>
                                                    </div>
                                                    <br />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
