import React, { useEffect } from "react";
import {BrowserRouter as Router, Route, Routes, useLocation, useHistory} from "react-router-dom";
import ContextLoader from "./library/ContextLoader";
import Index from "./pages/Index";
import Deal from "./pages/Deal";
import Login from "./pages/Login";
import Voice from "./pages/Voice";
import ViewDeal from "./pages/ViewDeal";
import Test from "./pages/Test";
import CustomerView from "./pages/CustomerView";
import axios from "axios";
import envConfig from "./env.config";


// pages
export default function (){

    return (
        // <h3>Maintenance. Try again in a few minutes.</h3>
        <Router>

                <Routes>  

                    <Route path="/" element={<ContextLoader/>}>
                        <Route path="/" element={window.localStorage["CS_VIEW"] ? <CustomerView/> : <Index/>}/>
                        <Route path="/deal/:dealId" element={ <Deal/>}/>
                        <Route path="/deal/view/:dealId" element={<ViewDeal/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/dev" element={<Voice/>}/>
                        <Route path="/test" element={<Test/>}/>
                    </Route>
                   
                    
                </Routes>
                    
        </Router>     
    )

}