export default {
    langIso: "fr",
    hello: "Bonjour",
    fillTheForm: "Remplissez le formulaire suivant pour continuer.",
    dealId: "Numéro de la transaction",
    firstName: "Prénom",
    lastName: "Nom",
    phoneNumber: "Numéro de téléphone",
    email: "Courriel",
    address: "Adresse",
    location: "Sélectionnez une concession",

    continue: "Continuer",

    watchVideo: "Regardez cette courte vidéo pour continuer",
    watchAllVideoToContinue: "Regarder le vidéo complet pour continuer",

    emailDocuments: "Documents par courriel",
    emailDocumentsDescription: "Vous receverez par courriel les documents détaillants vos droits en matière d'assurance.",

    signature: "Approbation et consentement",

    signature1: "J'ai regardé la vidéo et lu le document réglementaire, et j'accepte les termes et conditions de cette application.",
    signature2: "J'autorise HGrégoire à enregistrer ma conversation avec l'agent des ventes et à garder l'enregistrement pour une durée de 5 ans conformément aux lois applicables.",
    handSign: "Signez ici",

    record: "Informations de la transaction",
    recordButtonStart: "Continuer la session",
    recordButtonStop: "Terminer la session",

    confirmEnd: "Êtes-vous sûr de vouloir terminer la session ?",

    thanks: "HGrégoire vous remercie pour votre visite.",

    login: {
        title: "Login to",
        incorrectCredentials: "Incorrect credentials, please try again",
    },

    recording: "Enregistrement en cours",
}