import React, { useEffect, useRef, useState } from "react";
import langContent from "../lang";
import { get, post } from "../tools";
import ReactPlayer from "react-player";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import axios from "axios";
import { v4 as uuid } from "uuid";
import SignatureCanvas from "react-signature-canvas";
import Navbar from "../library/Navbar";
import envConfig from "../env.config";
import { useParams } from "react-router-dom";
import { useGeolocated } from "react-geolocated";

export default function Deal() {
    const params = useParams();
    const [lang, setLangContent] = useState(langContent["fr"]);
    const [session, setSession] = useState({
        step: 1
    });

    const setLang = (lang) => {
        setLangContent(langContent[lang]);
        nextStep();
    };

    const nextStep = (signatureBase64, coordinates) =>
        post("/session/nextStep/" + session._id, {
            step: session.step + 1,
            signatureBase64: signatureBase64 ? signatureBase64 : undefined,
            coordinates: coordinates
                ? coordinates
                : signatureBase64
                ? [0, 0]
                : undefined,
            lang: lang.langIsoq
        }).then((res) => setSession(session?.location?._id ? {
            ...res.data,
            location: session.location
        } : res.data
    ));
    
    useEffect(() => {
        get("/session/" + params.dealId).then((res) => {
            if (res.data.step === 6) window.location.href = "/";
            setSession(res.data);
            post("/session/pingActiveSession").then((res2) => {
                if (res2.data?._id?.toString() !== res?.data?._id?.toString())
                    window.location.href = "/";
            });
        });

        if ('wakeLock' in navigator) {
            navigator.wakeLock.request();
        }
    }, []);

    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false
            },
            userDecisionTimeout: 5000
        });

    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center">
                            {session?.location?._id === "62fd1adaa26a86312f5e5cfc" ? <img
                                src="https://finance.check-in.matador.ai/candiac.png"
                                width="200px"
                            /> : <img
                            src="https://mms.businesswire.com/media/20200916005892/fr/822032/5/A4RdwkEQ.jpg"
                            width="200px"
                        />}
                            <hr />
                        </div>
                        {session?._id ? (
                            session.step === 1 ? (
                                <Step1
                                    lang={lang}
                                    session={session}
                                    setLang={setLang}
                                />
                            ) : session.step === 2 ? (
                                <Step2
                                    lang={lang}
                                    session={session}
                                    nextStep={nextStep}
                                />
                            ) : session.step === 3 ? (
                                <Step3
                                    lang={lang}
                                    session={session}
                                    nextStep={nextStep}
                                />
                            ) : session.step === 4 ? (
                                <Step4
                                    coords={coords}
                                    lang={lang}
                                    session={session}
                                    nextStep={nextStep}
                                />
                            ) : session.step === 5 ? (
                                <Step5
                                    lang={lang}
                                    session={session}
                                    nextStep={nextStep}
                                />
                            ) : session.step === 6 ? (
                                <Step6
                                    lang={lang}
                                    session={session}
                                    nextStep={nextStep}
                                />
                            ) : null
                        ) : (
                            "loading..."
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Step1({ lang, session, setLang }) {
    return (
        <div className="row justify-content-center">
            <div className="col-12 text-center">
                <h3>
                    Bonjour {session?.customer?.firstName}{" "}
                    {session?.customer?.lastName}
                </h3>
                <hr />
                <h4>Choisissez une langue</h4>
                <h5>Choose a language</h5>
                <br />
                <button
                    className="btn btn-primary"
                    onClick={() => setLang("fr")}
                >
                    Français
                </button>
                <br />
                <br />
                <button
                    className="btn btn-primary"
                    onClick={() => setLang("en")}
                >
                    English
                </button>
            </div>
        </div>
    );
}

export function Step2({ lang, session, nextStep }) {
    const [playing, setPlaying] = useState(false);

    return (
        <div className="row text-center">
            <div className="col-12 text-center">
                <h5>{lang.watchVideo}</h5>
                <ReactPlayer
                    url={session?.location?._id === "62fd1adaa26a86312f5e5cfc" ? "https://finance.check-in.matador.ai/videos/AMF_TOYOTA_V2.mp4" : `https://finance.check-in.matador.ai/videos/${lang.langIso}-32d13213-1281-476f-83ac-a8ac33df215e.mp4`}
                    controls={false}
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "7em"
                    }}
                    playing={playing}
                    onProgress={(e) => {
                        if (e.played > 0.99) {
                            nextStep();
                        }
                    }}
                />
                <hr />
                <button
                    onClick={() => setPlaying(!playing)}
                    className="btn btn-success"
                >
                    {playing ? (
                        <React.Fragment>
                            <i className="fas fa-pause" /> Pause
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <i className="fas fa-play" /> Play
                        </React.Fragment>
                    )}
                </button>
                <br />
            </div>
        </div>
    );
}

export function Step3({ lang, session, nextStep }) {
    return (
        <div className="row justify-content-center">
            <div className="col-7 text-center">
                <h5>{lang.emailDocuments}</h5>
                <p>{lang.emailDocumentsDescription}</p>
            </div>
            <button className="btn btn-primary" onClick={() => nextStep()}>
                {lang.continue}
            </button>
        </div>
    );
}

export function Step4({ lang, session, coords, nextStep }) {
    React.useEffect(() => console.log(coords), [coords]);

    const [handSigned, setHandSigned] = useState(false);
    let signBoxRef = useRef();

    const nextStep1 = () => {
        const signature = signBoxRef
            .getTrimmedCanvas()
            .toDataURL("image/png")
            .replace("data:image/png;base64,", "");
        nextStep(signature, [
            coords?.latitude ? coords.latitude : 0,
            coords?.longitude ? coords.longitude : 0
        ]);
    };
    return (
        <div className="row justify-content-center">
            <div className="col-7 text-center">
                <h5>{lang.signature}</h5>
            </div>
            <div className="col-7">
                <div class="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked
                        value={true}
                    />
                    <label className="form-check-label">
                        {lang.signature1}
                    </label>
                </div>
                <br />
                <div class="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked
                        value={true}
                    />
                    <label className="form-check-label">
                        {lang.signature2}
                    </label>
                </div>
                <br />
                <h6>{lang.handSign}</h6>
                <SignatureCanvas
                    penColor="black"
                    backgroundColor="#ebebeb"
                    canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas"
                    }}
                    onEnd={() => setHandSigned(true)}
                    ref={(ref) => {
                        signBoxRef = ref;
                    }}
                />
            </div>
            <div className="col-7 text-center">
                <button
                    className="btn btn-primary"
                    onClick={nextStep1}
                    disabled={!handSigned ? true : undefined}
                >
                    {lang.continue}
                </button>
            </div>
        </div>
    );
}

export class Step5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issue: "",
            permission: "",
            recordState: "stop",
            recordId: uuid(),
            startedOnce: false,
            done: false,
            uploaded: false,
            uploadPercentage: 0
        };
    }

    start = () => {
        this.setState({ recordState: RecordState.START, startedOnce: true });
    };

    stop = () => {
        this.setState({ recordState: RecordState.STOP });
    };

    complete = () => {
        this.setState({
            recordState: RecordState.STOP,
            done: true,
            recordId: uuid()
        });
    };

    onStop = (audioData) => {
        const token = window.localStorage["USER_SESSION"];

        const form = new FormData();
        form.append("blob", audioData.blob, "audio.mp3");

        const config = {
            url:
                envConfig().API_URL +
                "/session/uploadChunk/" +
                this.props.session._id,
            method: "post",
            data: form,
            headers: {
                "Content-Type": "multipart/form-data",
                authorization: "Bearer " + token
            },
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                );
                this.setState({ uploadPercentage: percentCompleted });
            }
        };
        axios(config)
            .then((res) => {
                const complete = () => {
                    post("/session/pingActiveSession").then((res) => {
                        if (
                            res.data?._id?.toString() !==
                            this.props?.session?._id.toString()
                        )
                            this.complete();
                    });
                };
                if (this.state?.done) this.props.nextStep();
                else {
                    complete();
                    setTimeout(() => complete(), 5000);
                }
            })
            .catch((err) => {
                this.setState({
                    issue: "internet",
                    recordState: RecordState.STOP
                });
            });
    };

    updatePermission = (status) => {
        this.setState({ permission: status });
    };

    async componentDidMount() {
        const permission = await navigator.permissions.query({
            name: "microphone"
        });

        permission.onchange = function (a) {
            window.location.reload();
        };

        this.updatePermission(permission.state);

        setInterval(() => {
            if (RecordState.START === this.state.recordState) {
                this.stop();
                setTimeout(this.start, 0);
            }
        }, 10000);
    }

    render = () => (
        <div className="row justify-content-center">
            <div className="col-7 text-center">
                {this.state.permission === "denied" ? (
                    <div className="alert alert-danger">
                        Access to microphone denied. Please update the mic
                        permission and reload the page to continue
                    </div>
                ) : null}

                {this.state.issue === "internet" ? (
                    <div className="alert alert-danger">
                        There was an issue saving the last 10 seconds of
                        recording. Please check your internet connection and
                        reload the page
                    </div>
                ) : null}

                <h5>
                    {this.state.recordState === "start" ? (
                        <i
                            style={{ color: "black", marginTop: "20px" }}
                            className=" recordingButton fas fa-circle"
                        ></i>
                    ) : null}{" "}
                    {this.props.lang.record}{" "}
                </h5>
                <br />
                <div className="row justify-content-center">
                    <h5>
                        {this.props.session.customer.firstName}{" "}
                        {this.props.session.customer.lastName} (
                        {this.props.session.customer.dealId})
                    </h5>
                    <h5>{this.props.session.customer.email}</h5>
                </div>
                <hr />

                {!this.state.done ? (
                    this.state.recordState === "stop" ? (
                        <button
                            className="btn btn-lg btn-primary"
                            onClick={this.start}
                        >
                            {" "}
                            {this.props.lang.recordButtonStart}
                        </button>
                    ) : (
                        <button
                            style={{ marginRight: "5px" }}
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target={"#exampleModalPrefill"}
                            className="btn btn-lg btn-primary"
                        >
                            {this.props.lang.recordButtonStop}
                        </button>
                    )
                ) : null}

                <br />
                <br />

                <AudioReactRecorder
                    state={this.state.recordState}
                    onStop={this.onStop}
                    canvasHeight={0}
                    backgroundColor="#ffffff"
                    type="audio/mp3"
                />

                <br />

                {this.state.done && !this.state.uploaded ? (
                    <div className="alert alert-primary">
                        Chargement... Ne fermez pas cette page
                        <br />
                        Uploading... Do not close this page
                        <br />
                        <b>{this.state.uploadPercentage}%</b>
                    </div>
                ) : null}

                {!this.state.done &&
                !this.state.uploaded &&
                this.state.recordState === "stop" &&
                this.state.startedOnce ? (
                    <button
                        className="btn btn-primary"
                        onClick={this.props.nextStep}
                    >
                        {this.props.lang.continue}
                    </button>
                ) : null}

                <div
                    className="modal fade"
                    id={"exampleModalPrefill"}
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                {this.props.lang.confirmEnd}

                                <button
                                    type="button"
                                    data-bs-dismiss="modal"
                                    className="btn btn-lg btn-primary"
                                    onClick={this.complete}
                                >
                                    {" "}
                                    {this.props.lang.recordButtonStop}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function Step6({ lang }) {
    return (
        <div className="row justify-content-center">
            <div className="col-7 text-center">
                <h5>{lang.thanks}</h5>
            </div>
        </div>
    );
}
